let linkActiveClass = "uk-active";

let isDevelopment = ["dev", "development"].includes(process.env.NODE_ENV);
let isStage = ["stage", "staging"].includes(process.env.NODE_ENV);
let isProduction = ["production"].includes(process.env.NODE_ENV);

export default {
  requiresGuestRedirect: "/",
  requiresUserRedirect: "/user/login",
  requireSuperAdminRedirectPath: "user-login",
  linkActiveClass: linkActiveClass,
  preferredRouterHistory: isProduction || isStage ? "web" : "webHash",
  useI18n: false,
  middleware: {
    saffronBus: {
      showSpinner: true,
      spinnerDelay: 150,
      spinnerText: false,
    },
  },
};
