let debug;
let useSSR;
// if debug specifically on in env - debug on
if (
  process.env.VUE_APP_DEBUG &&
  process.env.VUE_APP_DEBUG !== "false" &&
  process.env.VUE_APP_DEBUG !== "0"
) {
  debug = true;
} else {
  debug = false;
}

if (
  process.env.VUE_APP_USE_SSR &&
  process.env.VUE_APP_USE_SSR !== "false" &&
  process.env.VUE_APP_USE_SSR !== "0"
) {
  useSSR = true;
} else {
  useSSR = false;
}

// if env reflects in an obvious manner that this is NOT production - debug is on
if (
  ["dev", "development", "test", "testing", "stage", "staging"].includes(
    process.env.NODE_ENV
  )
) {
  debug = true;
}

export default {
  env: process.env.NODE_ENV,
  debug,
  // should service worker be used
  useServiceWorker: false,
  // should saffron plugin use $s namespace
  useNamespaceS: true, // this is not error!!! use namespace S - capital S.
  // does this app expect SSR? notice server side config might also be required
  useSSR: useSSR,
  // server config override?
  useServerConfig: true,
  // should app wait for server config, or render as is and load it async
  waitForServerConfig: true,
  // server language override?
  useServerLanguage: true,
  // should app wait for server language, or get it asynchronously
  waitForServerLanguage: true,
  // should app use saffron user
  useSaffronUser: true,
  // should we use default socket functionality (mostly use states)
  useDefaultSocketFunctionality: true,
  // key for meta accessor. allows assignment from options to actual component
  metaDataAccessorKey: "saffronMetaDataAccessor",
  // key for layout accessor. allows assignment from options to actual component
  layoutAccessorKey: "saffronLayoutAccessor",
  // theme color for browsers - can not use css variables
  themeColor: "#4e469c",
  // a global key used to refer to pass a global around safely. no need to change this ever, but we need it here
  saffronAppGlobalKey: Symbol("saffron-app-global"),
};
