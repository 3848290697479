export default {
  frameworks: ["we"],
  frameworkComponents: ["we"],
  scss: ["styles"],
  responsive: {
    classMap: {
      // please define in app config. otherwise its comulative
      //  xsmall  : 400,
      //     small   : 640,
      //  medium  : 960,
      //   large   : 1200,
      //   xlarge  : 1600,
    },
    responsiveClassPrefix: "break-",
    responsiveClassMode: "mobileFirst",
  },
  defaultIconSet: ["rubicons"],
  defaultSpinnerType: "circle",
  dropdown: {
    animationClass: "animate__fadeInUpSmall animate--fastest",
    gap: 10,
  },
  weUI: {
    notification: {
      defaultAnimation: "fadeInUpSmall",
      defaultCloseAnimation: "fadeOut",
      defaultDuration: 5000,
      defaultPosition: "notifications"
    },
    button: {
      defaultTheme: "primary",
    },
    tooltip: {
      animationClassIn: "animate__fadeIn",
      animationClassOut: "animate__fadeOut",
      extraClass: "animate--faster",
    },
  },
  pageTransitionClassIn: "animate__fadeIn animate--fast",
  pageTransitionClassOut: "animate__fadeOut  animate--faster",
};
