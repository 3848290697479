export default async ({ asyncOps, store }) => {
  return {
    dashboard: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-dashboard" },
        },
      },
      icon: "dashboard",
      label: "admin.sideNav.dashboard",
    },
    ecommerceTitle: {
      action: {
        type: "title",
      },
      label: "admin.sideNav.ecommerce",
    },
    vendor: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-vendor" },
        },
      },
      icon: "cart",
      label: "admin.sideNav.vendor",
    },
    product: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-product" },
        },
      },
      icon: "bar-code",
      label: "admin.sideNav.product",
    },
    order: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-order" },
        },
      },
      icon: "credit-cards",
      label: "admin.sideNav.order",
    },
    generalTitle: {
      action: {
        type: "title",
      },
      label: "admin.sideNav.generalTitle",
    },
    entities: {
      action: false,
      icon: "layer-stack",
      label: "admin.sideNav.entities",
      children: {
        entityDashboard: {
          action: {
            type: "routerNavigation",
            data: {
              to: { name: "entity-dashboard" },
            },
          },
          icon: "layer-stack",
          label: "admin.sideNav.entityDashboard",
        },
        category: {
          icon: "handle-horizontal",
          label: "admin.sideNav.category",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "category" },
              },
            },
          },
        },
        tag: {
          icon: "hashtag",
          label: "admin.sideNav.tag",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "tag" },
              },
            },
          },
        },
        article: {
          icon: "pencil-write",
          label: "admin.sideNav.article",
          action: {
            type: "routerNavigation",
            data: {
              to: {
                name: "entity-list",
                params: { entityType: "article" },
              },
            },
          },
        },
      },
    },
    media: {
      label: "admin.sideNav.media",
      icon: "image",
      action: {
        type: "routerNavigation",
        data: {
          to: {
            name: "admin-media",
          },
        },
      },
    },
    userTitle: {
      action: {
        type: "title",
      },
      label: "admin.sideNav.userTitle",
    },
    user: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-user" },
        },
      },
      icon: "user",
      label: "admin.sideNav.user",
    },
    acl: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-acl" },
        },
      },
      icon: "lock",
      label: "admin.sideNav.acl",
    },
    settingsTitle: {
      action: {
        type: "title",
      },
      label: "admin.sideNav.settingsTitle",
    },
    language: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-language" },
        },
      },
      icon: "chat-message-alt",
      label: "admin.sideNav.language",
    },
    settings: {
      action: {
        type: "routerNavigation",
        data: {
          to: { name: "admin-config" },
        },
      },
      icon: "settings",
      label: "admin.sideNav.config",
    },
  };
};
