export default {
  defaultRequestAdapter: "saffronAxios",
  defaultResponseAdapter: "saffron",
  requestAdapters: {
    saffronAxios: {
      rootUrl: process.env.VUE_APP_AXIOS_ROOT_URL || "/api/",
      axiosRetryAttempts: 0,
    },
  },
  responseAdapters: {
    saffron: {},
  },
  cache: {
    cacheGetRequestsByDefault: true,
    defaultCacheTtlMs: 10 * 1000,
    minimalPurgeInterval: 10 * 1000,
  }
};
