/**
 * When a route meta has requiresGuest, will redirect logged in users based on store/auth module
 * Will hang and wait, if the auth status is not determined.
 *
 * If route meta has requiresGuestRedirect property - will redirect there
 * Otherwise, will redirect based on app config - config.router.requiresGuestRedirect
 * Finally, if both missing, will redirect to '/' as a last resort.
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
  return {
    routerMethod: "saffronBus",
    handler: async (to, from, context) => {
      // todo: user type support

      let loadingMessage = null;
      if (router.app.globalTranslate) {
        loadingMessage = "operatorAnon.authInProgress";
      }

      let spinnerTimeout = utilities.setClientTimeout(() => {
        router.app.store.commit("ui/showGlobalSpinner", loadingMessage);
      }, 300);
      // wait for user. no more than 2 seconds
      await utilities.waitForUser(router.app.store);

      clearTimeout(spinnerTimeout);
      router.app.store.commit("ui/showGlobalSpinner", false);
      // console.log(router.app, 'context');
      // TODO: impmplement ability to enqueue a notification. let Saffron comonent show the notification when mounted

      // redirect non-authenticated users
      if (!router.app.store.getters["user/isAuthenticated"]) {
        router.push({ name: "auth-operator" });
      }

      let profile = router.app.store.getters["user/profile"];
      if (!profile.isSuperAdmin) {
        return router.push({
          name: "auth-operator",
          params: { loginRedirect: to.fullPath },
        });
      }
    },
  };
};
